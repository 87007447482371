import { screen } from "@testing-library/react";
import { createIntl } from "utilities/testHelpers/createIntl";
import { messages as googleCompanyAutocompleteMessages } from "jobber/setupWizard/components/GoogleCompanyAutocomplete/messages";
import { mockSetupWizardStepProps } from "jobber/setupWizard/testHelpers";
import { messages } from "./messages";

const { formatMessage } = createIntl();
const companyNamePlaceholder = formatMessage(messages.companyNamePlaceholder);
const companyNameAutocompletePlaceholder = formatMessage(
  googleCompanyAutocompleteMessages.companyNamePlaceholder,
);
const companyNameError = formatMessage(messages.companyNameError);
const industryPlaceholder = formatMessage(messages.industryPlaceholder);
const industryError = formatMessage(messages.industryError);

export const companyNameInput = () =>
  screen.findByRole("textbox", {
    name: companyNamePlaceholder,
  });
export const companyNameInputLabel = () =>
  screen.findByLabelText(companyNamePlaceholder);
export const companyNameAutocompleteInput = () =>
  screen.findByRole("textbox", {
    name: companyNameAutocompletePlaceholder,
  });
export const companyNameAutocompleteInputLabel = () =>
  screen.findByLabelText(companyNameAutocompletePlaceholder);
export const companyNameAutocompleteInputWrapper = () =>
  screen.findByTestId("autocomplete-company-input");
export const queryCompanyNameAutocompleteInputWrapper = () =>
  screen.queryByTestId("autocomplete-company-input");
export const companyDescription = (description: string) =>
  screen.findByText(description);
export const companyNameAutocompleteOption = (option: string) =>
  screen.findByText(option);
export const companyNameErrorText = () => screen.findByText(companyNameError);
export const manualCompanyNameOption = () =>
  screen.findByText(
    `+ ${formatMessage(googleCompanyAutocompleteMessages.manualCompanyNameLabel)}`,
  );

export const industryInput = () =>
  screen.findByRole("textbox", {
    name: industryPlaceholder,
  });
export const industryInputLabel = () =>
  screen.findByLabelText(industryPlaceholder);
export const industryErrorText = () => screen.findByText(industryError);
export const industryDisplayName = (name: string) => screen.findByText(name);
export const industryCategory = (category: string) =>
  screen.findByText(category);

export const nextCTA = () =>
  screen.findByText(mockSetupWizardStepProps.navigation.nextCtaCopy);
